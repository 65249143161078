import React, { useRef } from "react"

import * as Yup from "yup"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Text from "../components/Text"
import Hero from "../components/Hero"
import { Button } from "../components/Button"
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik"
import { Fade } from "react-awesome-reveal"

export default function Contact() {
  // Refs
  const form = useRef<HTMLFormElement>(null)

  // Components

  const Input = ({
    field,
    name,
    label,
    ...props
  }: FieldProps & { name: string; label: string }) => (
    <div {...props}>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input
        {...field}
        className="block w-full p-4 pl-6 font-bold placeholder-gray-700 bg-gray-900 rounded-lg focus:outline-none"
        placeholder={label}
      ></input>
      <ErrorMessage
        {...field}
        render={(msg) => <p className="mt-4 text-sm">{msg}</p>}
      />
    </div>
  )

  const TextArea = ({
    field,
    name,
    label,
    ...props
  }: FieldProps & { name: string; label: string }) => (
    <div {...props}>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <textarea
        {...field}
        className="block w-full h-48 p-4 pl-6 font-bold placeholder-gray-700 bg-gray-900 rounded-lg resize-none md:h-full focus:outline-none"
        placeholder={label}
      ></textarea>
      <ErrorMessage
        {...field}
        render={(msg) => <p className="mt-4 text-sm">{msg}</p>}
      />
    </div>
  )

  return (
    <Layout>
      <SEO title="Contact" />
      <Hero>
        <span>
          Contact Us! Speak To A Member Of{" "}
          <span className="text-blue-500">Our Team</span>.
        </span>
      </Hero>

      <div className="bg-gray-800">
        <Section className="container p-8 mx-auto overflow-hidden lg:py-24">
          <Fade
            delay={100}
            duration={500}
            fraction={0.3}
            damping={0.2}
            cascade
            triggerOnce
          >
            <Text className="mb-6 tracking-wide">
              Do you need high-quality service and strategy for your next Esport
              event? We can help! We are also inviting you to become part of the
              lead production and distribution agency today.
            </Text>

            <Text className="mb-12">
              Our team not only offers delightful services but prides itself on
              its prompt response time. We&#39;re available for you 24/7. Reach
              us today!
            </Text>

            <Formik
              initialValues={{ name: "", email: "", subject: "", mesage: "" }}
              validationSchema={Yup.object({
                name: Yup.string().required("Please enter your name."),
                email: Yup.string()
                  .email("Please enter a valid email address.")
                  .required("Please enter your email address."),
                subject: Yup.string()
                  .max(30, "Please enter a subject less than 30 characters.")
                  .required("Please enter a subject."),
                message: Yup.string()
                  .max(200, "Please enter a message less than 200 characters.")
                  .required("Please enter a message."),
              })}
              onSubmit={(_, { setSubmitting }) => {
                form.current?.submit()
                setSubmitting(false)
              }}
            >
              <Form
                ref={form}
                className="grid gap-6 md:grid-cols-2"
                action="https://formspree.io/xdowjrpa"
                method="POST"
              >
                <div className="grid gap-6">
                  <Field name="name" label="Your name" component={Input} />
                  <Field name="email" label="Your email" component={Input} />
                  <Field
                    name="subject"
                    label={"Your subject"}
                    component={Input}
                  />
                </div>
                <Field
                  name="message"
                  label="Your message"
                  component={TextArea}
                />
                <Button className="mx-auto mt-12 md:col-span-2">Submit</Button>
              </Form>
            </Formik>
          </Fade>
        </Section>
      </div>
    </Layout>
  )
}
